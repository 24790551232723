<template lang="pug">
BaseModal(:showModal="showModalShare")
  .max-w-lg.w-full.modal-share
    BaseCard
      BaseCardTitle 
        .flex.items-center
          .text-2xl {{ lang.t('shareCV.title_share') }}
      .content
        p.message.pb-4 {{ lang.t('shareCV.message') }}
        BaseInputText(label="Correo electronico" v-model="emailToSend" :error="emailError")
        .alert-remember(v-if="successSend") 
          BaseCustomIcon(nameIcon="mdiCheck" :size="16")
          .txt {{ lang.t('shareCV.text_succeeded') }}
      template(#card-bottom)
        .flex.items-center.justify-end.gap-4
          .button(@click="showModalShare = false") {{ lang.t('shareCV.button_cancel') }}
          .button.close(@click="intentSendEmail") {{ lang.t('shareCV.button_send') }}
</template>

<script setup>
import { ref, defineProps, watch, toRefs, computed } from 'vue';
import validatorEmail from '@/composables/validator-email';
import { useStore } from 'vuex';
import { UsersRepository } from '@/repository/users.repository';
import { i18n } from '@/languages';
import { useRouter } from 'vue-router';
const lang = computed(() => i18n.global);

const store = useStore();
const router = useRouter();
const userEmail = store.getters['UserStore/getUserEmail']

const { isValidEmail } = validatorEmail();
const showModalShare = ref(false);
const emailToSend = ref(userEmail);
const emailError = ref('')
const successSend = ref(false)

const props = defineProps({
  triggerOpen: Number,
  curriculum_id: String
})

const { triggerOpen, curriculum_id } = toRefs(props)

const intentSendEmail = () => {
  console.log(isValidEmail(emailToSend.value))
  if (isValidEmail(emailToSend.value)) {
    sendEmail(curriculum_id.value, emailToSend.value)
  } else {
    emailError.value = lang.value.t('shareCV.error_email')
  }
}

const sendEmail = (curriculum_id, emailToSend) => {
  UsersRepository.sendToEmailCurriculum({
    curriculum_id,
    email_to_send: emailToSend
  }).then(successEmailSent).catch(({ response }) => {
    if (response.data.message &&
      response.data.message == "disabled-downloads") {
      router.push({
        name: "builder/payment/with-id",
        params: { curriculum_id: curriculum_id },
      });
    }
  })
}

const successEmailSent = () => {
  successSend.value = true;
  setTimeout(() => {
    showModalShare.value = false
  }, 1500)
}

watch(triggerOpen, () => {
  showModalShare.value = true
})

watch(emailToSend, () => {
  emailError.value = ''
})

</script>

<style lang="scss" scoped>
.modal-share {
  .content {
    @apply pt-2;
  }
  .message {
    @apply text-sm pt-2;

    &.access {
      @apply pt-0 text-gray-400 italic;
    }
  }

  .button {
    @apply text-gray-500 px-4 py-2 rounded-md cursor-pointer transition-colors;

    &:hover {
      @apply bg-gray-200;
    }

    &.cancel {
      @apply bg-red-400 text-white;
      &:hover {
        @apply bg-red-500;
      }
    }
    &.close {
      @apply bg-blue-400 text-white;
      &:hover {
        @apply bg-blue-500;
      }
    }
  }

  .alert-remember {
    @apply flex items-center border-2 border-green-200 rounded-md pl-2 pr-4 py-1 text-xs bg-green-50 text-green-800 font-medium mt-2;
    .txt {
      @apply ml-2;
    }
  }
}
</style>
